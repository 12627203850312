
import { defineComponent, ref, onMounted, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import { ApiPayment } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2";

export default defineComponent({
  name: "add-refund-modal",
  props: {
    paymentOrderNo: { type: String, required: true, default: "" },
  },
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addRefundModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const orderAmount = ref<number>(0);
    const refundableAmount = ref<number>(0);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      payment_order_no: "",
      pay_trade_no: "",
      sales_order_no: "",
      amount: 0,
      currency: "EUR",
      reason: "",
    });

    const rules = ref({
      // sales_order_no: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (Number(value) > refundableAmount.value) {
              return callback(new Error(t("payment.refundTip")));
            }
            callback(); // validation passed
          },
          trigger: "change",
        },
      ],
      reason: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.paymentOrderNo,
      (newVal) => {
        if (newVal) {
          formData.value.payment_order_no = newVal;
        }
      }
    );

    //实时查询可退金额
    const getPaymentData = async () => {
      loading.value = true;
      const { data } = await ApiPayment.getPaymentDetail({
        payment_order_no: props.paymentOrderNo,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value.payment_order_no = props.paymentOrderNo;
        formData.value.pay_trade_no = data.data.pay_trade_no;
        orderAmount.value = data.data.amount;
        refundableAmount.value = data.data.refundable_amount.amount;
      }
    };

    const createRefund = () => {
      ApiPayment.addRefund(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addRefundModalRef.value);
              emit("update-list");
            });
          } else if (data.code == 40002) {
            if (
              data.data.sales_order_no &&
              data.data.sales_order_no.length > 0
            ) {
              //销售订单号不存在
              Swal.fire({
                title: t("payment.errorMsg"),
                icon: "info",
                buttonsStyling: false,
                showCancelButton: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            } else {
              showServerErrorMsg(data);
            }
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          createRefund();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(addRefundModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value = {
        payment_order_no: "",
        pay_trade_no: "",
        sales_order_no: "",
        amount: 0,
        currency: "EUR",
        reason: "",
      };
    };

    onMounted(() => {
      modalShowListener(addRefundModalRef.value, () => {
        setTimeout(() => {
          getPaymentData();
        });
      });
    });

    return {
      props,
      loading,
      formData,
      rules,
      formRef,
      orderAmount,
      refundableAmount,
      addRefundModalRef,
      submitButton,
      t,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
